import React from 'react'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styled from 'styled-components'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import BrandItem from './BrandItem'
import SkeletonItem from '../Skeletons/SkeletonItem'

interface Brand {
  id: string
  image: {
    url: string
  }
  category?: {
    name?: string
    mainCategoryName?: string
  }
}

type Props = React.HTMLAttributes<HTMLDivElement> & {
  title?: string
  brands?: Brand[]
  testId?: string
  slidesTestId?: string
  loading?: boolean
  citySlug?: string
}

const { cssPrefix, toPrefix } = toCssPrefix('BrandsSlider__')
const Wrapper = styled.div`
  margin: 0 auto;

  .${cssPrefix} {
    &slide {
      width: 120px;
      height: 120px;
    }

    &title {
      font-size: 24px;
    }

    &swiper {
      .swiper-button {
        &-next,
        &-prev {
          color: ${({ theme }) => theme.colors.white};
          background-color: ${({ theme }) => theme.colors.black50};
          display: none;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          padding: 0;
          transition: 200ms;

          &::after {
            font-weight: bold;
            font-size: 16px;
          }
        }

        &-next {
          right: 0;
        }

        &-prev {
          left: 0;
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &swiper {
        .swiper-button {
          &-next,
          &-prev {
            display: flex;
          }
        }
      }
    }
  }
`
SwiperCore.use([Navigation])

const BrandsSlider = ({
  title,
  brands = [],
  className = '',
  testId = 'brands-slider',
  slidesTestId = 'brands-slider-slide',
  loading,
  citySlug,
  ...props
}: Props) => {
  return (
    <Wrapper data-testid={testId} className={`${className} ${cssPrefix}`} {...props}>
      <h3 className={toPrefix('title')}>{title}</h3>
      <Swiper className={toPrefix('swiper')} navigation spaceBetween={20} slidesPerView="auto">
        {loading ? (
          Array(14)
            .fill(0)
            .map((_, index) => (
              <SwiperSlide className={toPrefix('slide')} key={index}>
                <SkeletonItem height={140} animate />
              </SwiperSlide>
            ))
        ) : (
          <>
            {brands?.map((brand) => (
              <SwiperSlide data-testid={slidesTestId} className={toPrefix('slide')} key={brand.image?.url}>
                <BrandItem
                  categoryName={brand.category?.name}
                  name={brand.id}
                  citySlug={citySlug}
                  image={brand.image?.url}
                />
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>
    </Wrapper>
  )
}

export default BrandsSlider
