import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { toCssPrefix, breakpoints, City, sendClickProductToGTM, useLocation } from '@ecommerce/shared'
import { backgroundImageStyles } from '../../utils/styles'
import { ClientSideBlock, BlockType, Block } from '../../graphql/contentfulTypes'
import useQuery from '../../hooks/useQuery'
import Promotions from '../home/Promotions'
import ProductsSlidesList from '../../components/Moments/ProductSlidesList'
import ClientSideRecipeList from '../../components/Recipes/ClientSideRecipeList'
import BrandsSliderWidget from '../../components/BrandsSlider/BrandsSliderWidget'
import FilteredProductList from '../../components/Moments/FilteredProductList'

type GQLBlock = Pick<Block, 'title' | 'contentful_id' | 'blockType'>

type Props = {
  pgPageId: string
  city: City
  pageBlocks?: GQLBlock[]
  backgroundImage?: string
  themeId?: string
}

type BlocksQueryResult = {
  pgPage: {
    blocksCollection?: {
      items?: ClientSideBlock[]
    }
  }
}

const blocksQuery = `query PromotionsPage($pgPageId: String!) {
    pgPage(id: $pgPageId) {
      blocksCollection {
        items {
          blockType
          title
          sys {
            id
          }
        }
      }
    }
  }  
`

const { cssPrefix, toPrefix } = toCssPrefix('LiquorTemplate__')
const Wrapper = styled.div<{ backgroundImage?: string }>`
  min-height: 100vh;
  color: ${({ theme }) => theme.colors.bodyText};
  padding: 32px 0 40px;
  background-color: ${({ theme }) => theme.colors.brand.default};
  ${backgroundImageStyles()};

  .${cssPrefix} {
    &promo-cards {
      &-card {
        background-position: center;
      }

      &-title {
        margin-left: 0;
      }

      div[type='promo-large'] {
        &:first-child {
          padding-left: 0;
        }
      }
    }

    &product-slides {
      &-detail-button {
        color: ${({ theme }) => theme.colors.bodyText};
        border-color: ${({ theme }) => theme.colors.bodyText};
      }

      &-add-product-button:not(div) {
        background: ${({ theme }) => theme.colors.bodyText};
        color: ${({ theme }) => theme.colors.primary};
      }

      &-add-product-button:is(div) {
        background: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &promo-cards {
        &-title {
          text-align: center;
        }
      }
    }
  }
`

const BLockWrapper = styled.section<{ withPadding?: boolean; withMargin?: boolean }>`
  margin: ${({ withMargin = true }) => (withMargin ? '60px 0' : '0')};
  padding: ${({ withPadding }) => (withPadding ? '0 25px;' : '0')};

  @media screen and (${breakpoints.desktop.min}) {
    padding: ${({ withPadding }) => (withPadding ? '0 90px;' : '0')};
  }
`

const LiquorTemplate = ({ backgroundImage, pgPageId, themeId, city, pageBlocks }: Props) => {
  const [blocks, setBlocks] = useState(pageBlocks)

  const { data, loading } = useQuery<BlocksQueryResult>(blocksQuery, { variables: { pgPageId } })
  const blocksData = data?.pgPage?.blocksCollection?.items ?? []

  const { isBolivia } = useLocation()

  const formatClientSideBlocks = (): GQLBlock[] => {
    return blocksData?.map(({ blockType, title, sys }) => ({
      blockType,
      title,
      contentful_id: sys.id,
    }))
  }

  useEffect(() => {
    if (blocksData && !loading) {
      setBlocks(formatClientSideBlocks())
    }
  }, [data, loading])

  return (
    <Wrapper className={cssPrefix} backgroundImage={backgroundImage}>
      {blocks?.map((block) => {
        const { title, contentful_id: id } = block

        switch (block.blockType) {
          case BlockType.PROMO_LARGE:
            return (
              <BLockWrapper key={id} withMargin={false} withPadding>
                <Promotions themeId={themeId} citySlug={city.slug} className={toPrefix('promo-cards')} listId={id} />
              </BLockWrapper>
            )

          case BlockType.BRAND: {
            return (
              <BLockWrapper withPadding>
                <BrandsSliderWidget citySlug={city.slug} title={title} key={id} listId={id} />
              </BLockWrapper>
            )
          }

          case BlockType.PRODUCT_SLIDE: {
            return (
              <BLockWrapper key={id}>
                <ProductsSlidesList
                  listId={id}
                  title={title}
                  city={city}
                  themeId={themeId}
                  className={toPrefix('product-slides')}
                />
              </BLockWrapper>
            )
          }

          case BlockType.RECIPE: {
            return (
              <BLockWrapper key={id}>
                <ClientSideRecipeList title={title} themeId={themeId} citySlug={city.slug} listId={id} />
              </BLockWrapper>
            )
          }

          case BlockType.FILTERED_LIST: {
            return (
              <BLockWrapper key={id} withMargin={false}>
                <FilteredProductList
                  themeId={themeId}
                  city={city}
                  blockId={id}
                  onCardClick={({ product, listName }) => {
                    sendClickProductToGTM(product, listName, isBolivia())
                    navigate(`/${city.slug}/products/${product.skuCode}`, {
                      state: { themeId, originListName: listName },
                    })
                  }}
                />
              </BLockWrapper>
            )
          }

          default:
            return null
        }
      })}
    </Wrapper>
  )
}

export default LiquorTemplate
