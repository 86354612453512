import React from 'react'
import { graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import { PgPageProps } from '../types/PgPages'
import withPageTransition from '../components/withPageTransition'
import Layout from '../components/Layout'
import LiquorTemplate from '../templates/liquor'
import useTheme from '../hooks/useTheme'

const LiqueursPage = ({
  pageContext: { currentCity, productCategories, liqueursPage },
  data,
}: PgPageProps<{ liqueursPage: string }>) => {
  const pageData = data?.allContentfulPgPage?.edges?.[0]?.node
  const pageTheme = pageData?.theme

  const { mergeThemes } = useTheme(pageTheme?.contentful_id)

  return (
    <ThemeProvider theme={mergeThemes}>
      <Layout categories={productCategories} title={pageData.title} currentCity={currentCity}>
        <LiquorTemplate
          city={currentCity}
          pgPageId={liqueursPage}
          themeId={pageTheme?.contentful_id}
          backgroundImage={pageTheme?.bodyBackgroundImage?.file?.url}
          pageBlocks={pageData?.blocks}
        />
      </Layout>
    </ThemeProvider>
  )
}

export default withPageTransition(LiqueursPage)

export const query = graphql`
  query LiqueursPageQuery($liqueursPage: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $liqueursPage } }) {
      edges {
        node {
          title
          blocks {
            title
            contentful_id
            blockType
          }
          theme {
            contentful_id
            bodyBackground
            bodyBackgroundAlt
            bodyText
            brandActive
            brandDefault
            brandHover
            brandSelected
            brandTextActive
            brandTextDefault
            brandTextHover
            brandTextSelected
            productViewWrapperBackground
            productViewContainerBackground
            bodyBackgroundImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
