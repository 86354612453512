import React from 'react'
import BrandsSlider from '.'
import useQuery from '../../hooks/useQuery'

type Props = {
  listId: string
  title?: string
  testId?: string
  citySlug?: string
}

const query = `query BrandsList($listId: String!) {
    orList(id: $listId) {
      title
      itemsCollection {
        items {
          ... on Brand {
            id
            image {
              url
            }
            category {
              name
              mainCategoryName
            }
          }
        }
      }
    }
  }`

export type QueryResult = {
  orList: {
    title: string
    itemsCollection: {
      items: Array<{
        id: string
        image: {
          url: string
        }
        category?: {
          name?: string
          mainCategoryName?: string
        }
      }>
    }
  }
}

const BrandsSliderWidget = ({ listId, title, testId = 'brands-slider-widget', citySlug }: Props) => {
  const { data, loading } = useQuery<QueryResult>(query, { variables: { listId } })

  const list = data?.orList
  const brands = list?.itemsCollection?.items

  return (
    <BrandsSlider citySlug={citySlug} testId={testId} brands={brands} title={list?.title || title} loading={loading} />
  )
}

export default BrandsSliderWidget
