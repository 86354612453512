import React from 'react'
import styled from 'styled-components'
import { Image } from '@ecommerce/shared'
import { navigate } from 'gatsby'
import { Anchor } from '../common'

type Props = {
  name?: string
  image?: string
  citySlug?: string
  categoryName?: string
}

const Wrapper = styled.div<Props>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.brandText.default};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 5px;

  .anchor {
    width: 100%;
    height: 100%;
    cursor: ${({ categoryName }) => (categoryName ? 'pointer' : 'auto')};
  }

  .brand-image {
    width: 100%;
    height: 100%;
    display: block;
    margin: auto;
  }
`

const BrandItem = ({ image, categoryName, citySlug, name }: Props) => {
  const onNavigate = (event: React.MouseEvent) => {
    event.preventDefault()
    if (categoryName && name) {
      navigate(`/${citySlug}/category/${categoryName}?brandName=${name}`)
    }
  }

  return (
    <Wrapper categoryName={categoryName}>
      <Anchor className="anchor" onClick={onNavigate}>
        <Image className="brand-image" src={image} alt="brand-item" />
      </Anchor>
    </Wrapper>
  )
}

export default BrandItem
